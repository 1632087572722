@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.DetailsProvider_root {
  .CreateAndUpdateProvider_wrapper {
    max-width: 65%;
  }

  .CurrencyForm_box {
    margin: 0 0 15px 0;
  }

  .CurrencyForm_wrapper {
    .CurrencyForm_box {
      &:last-child {
        margin: 0;
      }
    }
  }

  .providerDetailsItem {
    margin: 0 0 24px 0;
  }

  .CreateAndUpdateProvider_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0;
  }

  .CreateAndUpdateProvider_select {
    margin-bottom: 15px;
  }

  .CreateAndUpdateProvider_checkbox-label {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-right: 50px;
  }

  .CreateAndUpdateProvider_septum {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
    border-bottom: 1px solid rgba(#2af3f3, 0.1);
    padding-bottom: 9px;
  }

  .CreateAndUpdateProvider_add-btn {
    margin: 16px 0;
  }

  .CreateAndUpdateProvider_currency-forms {
    padding: 0 24px;
  }

  .CreateAndUpdateProvider_footer-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .CreateAndUpdateProvider_footer-btn {
      flex: 0 1 31%;
    }
  }
}
